<template>
  <el-container class="container">
    <el-header class="container-top" style='height:6rem;'>
      <Header />
    </el-header>
    <el-main class="container-center" id="scroll">
      <div class="container-center-shouye">
        <div class="container-center-shouye-top">
          <div class="text">
            <div>智慧城市建造者</div>
            <div>至诚无息 律严待己 欲讷于言 而敏于行 诚信到永远</div>
          </div>
          <img src="../assets/imgs/banner.png" />
        </div>
        <div class="container-center-company">
          <company></company>
        </div>
        <div class="container-center-product">
          <product></product>
        </div>
        <div class="container-center-typical">
          <typical></typical>
        </div>
        <div class="container-center-news">
          <corporateNews></corporateNews>
        </div>
        <div class="container-center-org">
          <cooperativeAgency></cooperativeAgency>
        </div>
      </div>
      <div class="container-center-footer"  >
        <Footer></Footer>
      </div>
    </el-main>
  </el-container>
   
</template>

<script>
import Header from "./components/Header.vue";
// import Honor from "./honorCenter/Honor1.vue";
import company from "./companyIntro/company.vue";
// import orgCenter from "./organization/orgCenter.vue";
import cooperativeAgency from "./organization/cooperativeAgency.vue";
import Footer from "./components/Footer.vue";
import product from "./productCenter/product.vue";
import typical from "./typicalCase/typical.vue";
import corporateNews from "./newsCenter/corporateNews.vue";
export default {
  name: "home",
  components: {
    Header,
    company,
    // orgCenter,
    Footer,
    product,
    typical,
    corporateNews,
    cooperativeAgency,
  },
  mounted() {
    const hash = window.location.hash;
    if (hash === "#footer") {
      window.scrollTo(0, document.body.scrollHeight);
    }
  },
};
</script>

<style lang="less" scoped>
#scroll{
  overflow: scroll;
  &::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
}
.el-main {
  padding: 0;
}
.container {
  // width: 100%;
  //min-width: 1200px;
  &-top {
    z-index: 999;
    position: fixed;
    top: 0px;
    width: 100%;
    background: #fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    //min-width: 800px;
  }
  &-center {
    position: absolute;
    top: 8.4rem;
    width: 100%;
    height: calc(100vh - 8.4rem);
    box-sizing: border-box;
    &-shouye {
      width: 100%;
      &-top {
        width: 100%;
        height: 60rem;
        & > img {
          width: 100%;
          height: 100%;
          // position: relative;
        }
        .text {
          position: absolute;
          margin-top: 18rem;
          margin-left: 7rem;
          color: #fff;
          & > div:nth-of-type(1) {
            font-size: 5.5rem;
            width: 56rem;
            height: 8rem;
            letter-spacing: 0.8rem;
          }
          & > div:nth-of-type(2) {
            font-size: 2.3rem;
            color: #d4d8df;
            margin-top: 1.5rem;
            letter-spacing: 0.3rem;
          }
        }
      }
    }
    &-company {
      width: 100%;
    }
    &-product {
      width: 100%;
    }
    &-typical {
      width: 100%;
      // height: 600px;
    }
    &-news {
      width: 100%;
    }
    &-org {
      width: 100%;
    }
    &-footer {
      width: 100%;
    }
  }
}
</style>>
