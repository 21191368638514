<template>
  <div class="company">
    <div class="company-cont">
      <div class="company-cont-title">
        <div class="company-cont-title-enlish">
          <div class="line"></div>
          <div class="text">About Us</div>
        </div>
        <div class="company-cont-title-chinese">公司简介</div>
      </div>
      <div class="company-cont-info">
        <div class="company-cont-info-introduce">
          <div class="cont">
            
            <div class="cont-paragraph1" >
              湖北中科网络科技股份有限公司成立于1998年5月，注册地为武汉市武昌区，总部位于中国光谷·武汉东湖新技术开发区。
            </div>
            <div class="cont-paragraph1"
            >
              公司是智能化、信息化领域里集软件开发、系统集成、项目设计、施工、运维为一体的高新技术企业。特别在人工智能、大数据技术方面有所突破。经过25年的耕耘，公司在智能化、信息化项目、信息系统集成领域具备全面的资质和认证。经国家有关部门评定，公司先后获得湖北省优秀企业、湖北省软件优秀企业、湖北省重合同守信用企业、武汉十大科技创新企业、湖北省专精特新企业等荣誉。
            </div>
            <div class="cont-paragraph1"
            >
              公司以“诚信到永远”的工作理念，以感恩、创新、共享的工作态度，以优秀的项目设计、优质的项目实施、优良的产品和最优的服务来满足市场和客户的需求。
            </div>
          </div>
          <div class="img">
            <div class="img-tupian">
              <img src="../../assets/imgs/illustration-1.png" alt="" />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          num: "25",
          name: "年高薪技术企业",
        },
        {
          num: "6",
          name: "大领域",
        },
        {
          num: "25",
          name: "人中高级职称人员",
        },
        {
          num: "60",
          name: "项专利和著作权",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.company {
  top: 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  // height: 80vh;
  //height: 85rem;
  width: 100%;
  background-color: #fff;
  &-cont {
    margin-top: 6rem;
    display: flex;
    width: 90%;
    &-title {
      width: 16%;
      &-enlish {
        display: flex;
        .line {
          width: 6rem;
          border: 1px solid #1a2d51;
          height: 0;
          margin: 2rem 0.5rem 0 0;
        }
        .text {
          color: #1a2d51;
          font-size: 3rem;
          //font-weight: bold;
          height: 2rem;
          width: 25rem;
          font-family: 'Impact';
        }
      }
      &-chinese {
        font-size: 3rem;
        font-weight: bold;
        color: #1a2d51;
        width: 20rem;
        height: 3.5rem;
        margin-top: 2rem;
        letter-spacing: 0.8rem;
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      &-introduce {
        display: flex;
        align-content: center;
        flex-direction: row;
        width: 100%;
        height: 70rem;
        flex: 1.3;
        .cont {
          margin-top: 10rem;
          flex: 1.5;
          &-title {
            //width: 340px;
            height: 5rem;
            font-size: 3rem;
            font-weight: bold;
          }
          &-paragraph1 {
            font-size: 1.6rem;
            text-indent: 2em;
            margin: 1rem 0 1rem 0;
            color: #666;
            line-height: 3.5rem;
          }
        }
        .img {
          flex: 1;
          margin: 8rem 0 0 7.5rem;
          width: 45rem;
          height: 48rem;
          &-tupian {
            width: 45rem;
            height: 48rem;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      &-ter {
        flex: 0.9;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        margin-top:4rem;
        &-text {
          width: 27rem;
          height: 12rem;
          margin-top: 1rem;
          background: url("../../assets/imgs/frame-1.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          & > div:nth-of-type(1) {
            font-size: 5rem;
            font-weight: bold;
            color: #1987da;
            // width: 25px;
            // height: 35px;
          }
          & > div:nth-of-type(2) {
            font-size: 2rem;
            font-weight: bold;
            margin-top:2rem;
            // width: 160px;
            // height: 20px;
          }
        }
      }
    }
  }
}
</style>