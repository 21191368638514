<template>
  <div class="footer">
    <div class="footer-cont">
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="footer-cont-logo-img">
            <div class="footer-cont-logo-img-img1">
              <img src="../../assets/imgs/logo3.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="12" :offset="1" style="padding-right: 1rem;">
          <div class="footer-cont-phone-info">
            <div
              @click="jumpPage(1)" style="font-size:1.5rem"
            >
              软件产品
              <!-- <router-link :to="{ path: '/product' }"></router-link> -->
            </div>
            <div
              @click="jumpPage(2)"  style="font-size:1.5rem"
            >
              企业新闻
              <!-- <router-link :to="{ path: '/news' }">企业新闻</router-link> -->
            </div>
            <div
              @click="jumpPage(3)" style="font-size:1.5rem"
            >
              典型案例
              <!-- <router-link :to="{ path: '/case/government' }"
              >典型案例</router-link
            > -->
            </div>
            <div
              @click="jumpPage(4)" style="font-size:1.5rem"
            >
              荣誉资质
              <!-- <router-link :to="{ path: '/honor' }">荣誉资质</router-link> -->
            </div>
          </div>
          <div class="footer-cont-phone">
            <div class="tel">电话：027-87854392 027-87854062</div>

            <div class="emil">&emsp;&emsp;&emsp;400-0027-366</div>
            <div class="detail">
              办公地址：武汉市东湖新技术开发区藏龙岛武汉研创中心东区27栋
            </div>
            <div class="detail">
              注册地址：武汉市武昌区武珞路628号亚贸广场A座26-4
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="footer-cont-addr">
            <!-- <div class="lianxi1">联系热线：</div>

            <div class="dianhua">400-0027-366</div> -->

            <div class="qr">
              <img src="../../assets/img/ewm.jpg" alt="" />
            </div>
            <div class="weixin">
              微信公众号
            </div>

            <!-- <div class="anniu">
              <el-button type="primary" style="width: 150%">联系我们</el-button>
            </div> -->
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="text">
      Copyright2020 湖北中科网络科技股份有限公司 电话:027-87854392 027-87854062
      版权所有 All rights reserved.<img
        src="../../assets/img/beian.png"
      />鄂公网安备 42010602000518号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        text: "",
        id: "",
        age: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.form = "";
    },

    jumpPage(num) {
      if (num === 1) {
        this.$router.replace("/product");
      } else if (num === 2) {
        this.$router.replace("/news");
      } else if (num === 3) {
        this.$router.replace("/case/detail");
      } else {
        this.$router.replace("/honor");
      }
    },
  },
};
</script>

<style scoped lang="less">
.router-link-active {
  text-decoration: none;
  //color: yellow;
}
a {
  text-decoration: none;
  color: #aca8a8;
}
.footer {
  background: #303437;
  // height: 500px;
  width: 100%;
  padding-top: 3rem;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  &-cont {
    width: 90%;
    padding-top: 3rem;
    margin: 0 5%;
    height: 30rem;
    // top: 0rem;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    &-logo {
      height: 100%;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 50%;
      justify-content: flex-start;
      &-img {
        width: 100%;
        height: 70%;
        // display: flex;
        // align-content: flex-end;
        //background-color: #fff;
        &-img1 {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          & > img {
            //height: 40%;
            width: 15rem;
            //margin-left: 2rem;
            margin-top: 1rem;
          }
        }
      }
    }
    &-phone {
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
      flex-direction: column;
      color: #838383;
      line-height: 3.6rem;
      margin-top: 3rem;
	  & > div {
	    font-size:1.5rem;
	  }
      &-info {
        margin-top: 2rem;
        display: flex;
        color: #aca8a8;
        justify-content: flex-start;
        width: 100%;
        & > div {
          width: 17rem;
		  font-size:1rem;
        }
        & > div:hover {
          cursor: pointer;
          color: #fff;
        }
      }
    }
    &-addr {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-top: 2rem;
      &-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .way {
          width: 70%;
          & > div {
            font-size: 3rem;
            font-weight: bold;
            color: #fff;
            width: 100%;
            margin-bottom: 1rem;
          }
          & > button {
            width: 16.5rem;
            margin-top: 0.75rem;
            font-size: 2rem;
          }
        }
        .QR {
          width: 30%;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  // &-addr {
  //   display: flex;
  //   flex-direction: column;
  //   width: 80%;
  //   margin-left: 47rem;
  //   &-detail {
  //     color: #aca8a8;
  //     font-size: 18px;
  //   }
  //   & > div:nth-of-type(2) {
  //     margin-top: 20px;
  //   }
  // }
}
.text {
  font-size: 1rem;
  color: #a9a9a9;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 0;
}
.lianxi {
  display: flex;
  color: #aca8a8;
  font-size: 2rem;
  width: 100%;
  height: 30%;
  align-items: flex-end;
}

.lianxi1 {
  display: flex;
  color: #aca8a8;
  font-size: 1.5rem;
  width: 100%;
  height: 20%;
  align-items: flex-end;
  justify-content: center;
}
.weixin {
  display: flex;
  color: #aca8a8;
  // font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  margin: 1.5rem 0;
  // height: 10%;
  // align-items: flex-end;
  justify-content: center;
}
.qr {
  width: 100%;
  display: flex;
  justify-content: center;
  & > img {
    width: 11rem;
    // height: 50%;
  }
}
.anniu {
  display: flex;
  justify-content: center;
  // width: 30%;
  margin-top: 1.5rem;
}
</style>
