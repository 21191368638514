<template>
  <div class="news">
    <div class="news-cont">
      <div class="news-cont-title" @click="jump">
        <div class="news-cont-title-enlish">
          <div class="line"></div>
          <div class="text">Corporate News</div>
        </div>
        <div class="news-cont-title-chinese">企业新闻</div>
      </div>
      <div class="news-cont-info">
        <div class="left-title">
          <div class="img">
            <img src="../../assets/imgs/news0814.png" alt="" />
          </div>
          <div class="news-title">
            <div>2024-08-14</div>
            <div><span>今日新闻:</span><span>中科网络迎来武汉大学计算机学院专家考察</span></div>
          </div>
        </div>
        <div class="main">
          <div class="main-content" v-for="(item, i) in list" :key="i">
            <div class="main-content-date">
              <div>{{ item.year }}</div>
              <div>{{ item.month }}</div>
            </div>
            <div class="main-content-name">
              <div @click="jumpNewsDetail(item)" class="title-ellipsis">{{ item.title }}</div>
              <div class=".text-ellipsis">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
      {
          title: "中科网络迎来武汉大学计算机学院专家考察，共谋AI大模型技术新篇章",
          date: "2024.08",
          year: "2024",
          month: "08-14",
          text: "2024年8月12日，武汉大学计算机学院人工智能系主任、博士生导师彭敏教授及其团队成员博士田纲副教授，专程莅临中科网络进行考察与指导。中科网络对此次的到访表示了热烈欢迎，并由公司董事长袁建华、副董事长夏继征、副总经理肖科军及软件研发中心总监商飞鹏全程陪同交流。",
          imgS: require("@/assets/imgs/news0814.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/StX7xSyI9K7tJG8oAhcJsg'
        },
        {
          title: "夏日送清凉、防暑保安康丨湖北中科网络科技股份有限公司开展“夏日送清凉，防暑保安康”慰问活动",
          date: "2024.07",
          year: "2024",
          month: "07-25",
          text: "目前，正值高温酷暑的三伏时节，为切实做好职工的防暑降温和劳动保护工作，深切关怀一线员工的身心健康，不断增强员工的安全感、幸福感、获得感。为确保安全生产、项目质量进度顺利推进，公司开展了夏日送清凉慰问活动。",
          imgS: require("@/assets/imgs/news0725.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/2y2GC5K5t1nIE9qfTAROwg'
        },
      {
          title: "百城会团队一行到协会副会长单位中科网络参观交流",
          date: "2024.07",
          year: "2024",
          month: "07-03",
          text: "2024年6月27日，深圳市安全防范行业协会常务副秘书长黄云萍，副秘书长、CPS中安网行业分析师杨儒，CPS中安网编辑陈树剑及先讯科技、西部数据国代伟仕佳杰、照彰实业等多家企业代表一行，到访协会副会长单位湖北中科网络科技股份有限公司进行座谈交流。",
          imgS: require("@/assets/imgs/news0703.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/eo6pQbd6FOLgVwBD8KZ_hg'
        },
      {
          title: "党徽闪耀 身边榜样|致敬优秀共产党员",
          date: "2024.07",
          year: "2024",
          month: "07-02",
          text: "党徽闪耀 身边榜样|致敬优秀共产党员",
          imgS: require("@/assets/imgs/news0702.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/ZlSkBUPzcno1rLixe9W_LA'
        },
      
      

      ],
      row: {},
    };
  },
  methods: {
    jump() {
      this.$router.replace("/news");
    },

    jumpNewsDetail(item) {
      window.open(item.src,'_blank');
      // sessionStorage.setItem("title", JSON.stringify(item));
      // this.$router.replace("/newsDetail");
    },
    jumpNewsDetail1(item) {
      sessionStorage.setItem("title", JSON.stringify(item));
      this.$router.replace("/newsDetail");
    },
  },
};
</script>

<style scoped lang="less">
.news {
  top: 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  //height: 750px;
  // height: 85vh;
  width: 100%;
  background-color: #f1f5f8;
  &-cont {
    display: flex;
    width: 90%;
    flex-direction: column;
    &-title {
      height: 15%;
      width: 100%;
      margin: 5rem 0 4rem 0;
      cursor: pointer;
      &-enlish {
        display: flex;
        .line {
          width: 5.5rem;
          border: 1px solid #1a2d51;
          height: 0;
          margin: 2rem 0.5rem 0 0;
        }
        .text {
          color: #1a2d51;
          font-size: 2.5rem;
          //font-weight: bold;
          height: 4rem;
          width: 35rem;
          font-family: 'Impact';
        }
      }
      &-chinese {
        font-size: 3rem;
        font-weight: bold;
        color: #1a2d51;
        width: 19rem;
        height: 4.5rem;
        margin-top: 1rem;
        letter-spacing: 0.8rem;
      }
    }
    &-info {
      flex: 1;
      display: flex;
      flex-direction: row;
      // width: 100%;

      .left-title {
        width: 70rem;
        display: flex;
        flex-direction: column;
        .img {
          // position: relative;
          width: 70rem;
          height: 53rem;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        .news-title {
          position: absolute;
          margin-top: 29rem;
          background-color: rgba(11, 29, 51, 0.5);
          height: 10rem;
          width: 70rem;
          color: #fff;
          // z-index: 1;
          & > div {
            color: #fff;
            font-size: 1.4rem;
            // margin-left: 20px;
            margin-top: 2rem;
            opacity: 1;
          }
          div:nth-of-type(1) {
            font-size: 2.2rem;
            // width: 125px;
            height: 1.7rem;
            margin-left: 4.2rem;
          }
          div:nth-of-type(2) {
            font-size: 1.8rem;
            // width: 216px;
            height: 1.8rem;
            margin-left: 4.2rem;
          }
        }
      }
      .main {
        width: 60%;
        margin-left: 3rem;

        &-content {
          display: flex;
          flex-direction: row;
          color: #0f3f84;
          border-bottom: 1px solid #dedfe0;
          height: 12.5rem;
          margin: 0 0 1rem 0;
          &-date {
            width: 15%;
            padding-top: 0rem;
            // margin-bottom: 20px;
            & > div:nth-of-type(1) {
              font-size: 2rem;
              margin: 0 0 2.2rem 3rem;
              font-weight: bold;
              width: 4.5rem;
              height: 2.2rem;
            }
            & > div:nth-of-type(2) {
              font-size: 2.5rem;
              margin-left: 1rem;
              font-weight: bold;
              width: 10rem;
              height: 3rem;
            }
          }
          &-name {
            flex: 1;
            margin-left: 3rem;
            padding-top: 0rem;
            // margin-bottom: 10px;
            & > div:nth-of-type(1) {
              font-size: 2rem;
              margin: 0 0 1rem 0px;
              color: #000;
              font-weight: bold;
              // height: 24px;
            }
            & > div:nth-of-type(2) {
              font-size: 1.5rem;
              color: #333;
              line-height: 3rem;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              height: 6rem;
              margin-top: 1.5rem;
            }
            & > div:hover {
              color: #1a2d51;
              cursor: pointer;
              // display: inline-block;
              text-decoration: underline;
            }
            .title-ellipsis{
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              overflow:hidden;
              -webkit-box-orient: vertical;
              height: 3rem;
            }
            .text-ellipsis{
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 2;
              overflow:hidden;
              -webkit-box-orient: vertical;
              height: 2rem;
            }
          }
        }
      }
    }
  }
}
</style>