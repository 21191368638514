<template>
  <div class="sub-menu">
    <div class="list">
      <div
        class="item"
        v-for="(item, key) in list"
        :key="key"
        @click="toViewMethod(item)"
      >
        <div>
          <img :src="require(`../../assets/imgs/${item.src}`)" alt="" />
        </div>
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import store from "@/store";
export default {
  data() {
    return {
      list: [
        {
          src: "zhengwu-2.png",
          title: "智慧政务",
          text: "政党机关信息技术创新",
          path: "government",
        },
        {
          src: "guofang-2.png",
          title: "智慧国防",
          text: "军工企业信息化建设",
          path: "security",
        },
        {
          src: "yiliao-2.png",
          title: "智慧医疗",
          text: "数字医院 移动医疗 电子档案",
          path: "hospital",
        },
        {
          src: "gongan-2.png",
          title: "智慧交通",
          text: "智能交通 定位管理 精准导航",
          path: "safety",
        },
        {
          src: "jiaoyu-2.png",
          title: "智慧教育",
          text: "智慧校园 智慧课堂 智慧教研",
          path: "education",
        },
        {
          src: "yuanqu-2.png",
          title: "智慧园区",
          text: "数字展厅 孪生工厂 智能监控",
          path: "garden",
        },
      ],
    };
  },
  methods: {
    toViewMethod: ({ title }) => {
      const currentPath = router.currentRoute.path;
      const targetPath = "/case/detail";
      store.dispatch("changeDispatch", title);
      store.dispatch("changeDispatchTitle", null);
      if (currentPath !== targetPath) {
        router.push(targetPath);
      }
    },
  },
};
</script>
<style scoped lang="less">
.sub-menu {
  .list {
    display: grid;
    grid-template-columns: 45rem 45rem;
    grid-gap: 1.5rem;
    justify-content: center;
    padding: 1rem 1rem;
    .item {
      height: 6rem;
      cursor: pointer;
      display: flex;
      border-radius: 6px;
      padding: 0.8rem;
      img {
        width: 2.2rem;
        height: 2.2rem;
      }
      .right {
        margin-left: 0.6rem;
        .title {
          font-size: 1.2rem;
          color: black;
          line-height: 1;
        }
        .text {
		  font-size:0.8rem;
          margin-top: 0.8rem;
          color: #8c8c8c;
        }
      }
    }
    .item:hover {
      background-color: #f2f7fd;
    }
  }
}
</style>
