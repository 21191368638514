<template>
  <div class="case">
    <div class="case-cont">
      <div class="case-cont-title" >
        <div class="case-cont-title-info" @click="jump">
          <div class="case-cont-title-info-enlish">
            <div class="line"></div>
            <div class="text">Typical Case</div>
          </div>
          <div class="case-cont-title-info-chinese">典型案例</div>
        </div>
        <div class="case-cont-title-know" @click="jump">了解更多</div>
      </div>
      <div class="case-cont-info">
        <div class="module" v-for="(item, i) in typicalList" :key="i" @click="jupmPage(item)">
          <div class="img1">
            <img :src="item.img" alt="" />
          </div>
          <div class="content">
            <div class="xuhao" >{{ item.xuhao }}</div>
            <div class="name">{{ item.name }}</div>
            <div class="line"></div>
            <div class="text">{{ item.describe }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
export default {
  data() {
    return {
      typicalList: [
        {
          title: "智慧政务",
          img: require("../../assets/imgs/anli-1.png"),
          xuhao: "01",
          name: "某省人民政府",
          describe:
            "基于反恐和场规模式下的综合防控系统荣获2016年武汉市安防系统典型示范工程。",
        },
        {
          title: "智慧国防",
          img: require("../../assets/imgs/anli-2.png"),
          xuhao: "02",
          name: "湖北省军区",
          describe: "机房工程系统、智慧营区系统、短波信系统、计算机网络。",
        },
        {
          title: "智慧医疗",
          img: require("../../assets/imgs/anli-3.png"),
          xuhao: "03",
          name: "武汉市第一人民医院",
          describe: "安全监控系统、巡更管理系统、宣教系统、机房系统工程。",
        },
        {
          title: "智慧政务",
          img: require("../../assets/imgs/anli-4.png"),
          xuhao: "04",
          name: "中共某省委宣传部",
          describe:
            "建筑智能化系统、停车场管理系统、楼宇控制系统、综合安防系统、会议室及多媒体系统、消防大屏。",
        },
        {
          img: require("../../assets/imgs/anli-5.png"),
          xuhao: "05",
          name: "华中科技大学",
          describe: "数字化校园机房系统工程",
          title: "智慧教育",
        },
      ],
    };
  },
  methods:{
    jupmPage:({title,name})=>{
      // this.$router.replace("/case/detail");
      const currentPath = router.currentRoute.path;
      const targetPath = "/case/detail";
      store.dispatch("changeDispatch", title);
      store.dispatch("changeDispatchTitle", name);
      if (currentPath !== targetPath) {
        router.push(targetPath);
      }
    },
    jump() {
      this.$router.replace("/case/detail");
    },
  }
};
</script>

<style lang="less" scoped>
.case {
  top: 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  // height: 85vh;
  //height: 101rem;
  width: 100%;
  background-color: #fff;
  margin-bottom: 6rem;
  &-cont {
    display: flex;
    width: 90%;
    flex-direction: column;
    &-title {
      height: 15%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 7rem;
      &-info {
        cursor: pointer;
        &-enlish {
          display: flex;
          // width: 250px;
          .line {
            width: 6rem;
            border: 1px solid #1a2d51;
            height: 0;
            margin: 1.8rem 0.5rem 0 0;
          }
          .text {
            font-family: 'Impact';
            color: #1a2d51;
            font-size: 2.8rem;
            //font-weight: bold;
            height: 2rem;
            width: 35rem;
            font-weight: bold;
          }
        }
        &-chinese {
          font-size: 3.5rem;
          font-weight: bold;
          color: #1a2d51;
          height: 3.5rem;
          width: 25rem;
          margin-top: 2.5rem;
          letter-spacing:0.8rem;
        }
      }
      &-know {
        width: 16rem;
        height: 5rem;
        border: 1px solid #3e5f96;
        border-radius: 2.5rem;
        color: #3e5f96;
        line-height: 5rem;
        margin-top: 2rem;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
    &-info {
      // flex: 1;
      // height: 90%;
      //height: 68rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 3rem;
       cursor: pointer;
      & > div {
        width: 18%;
        box-shadow: 0 0 0.7rem #dddcdc;
      }
      .module {
        display: flex;
        flex-direction: column;
        height: 100%;
        // width: 310px;
        .img1 {
          height: 27rem;
          // width: 310px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          // height: 40%;
          //height: 27rem;
		  margin-bottom:3rem;
          display: flex;
          flex-direction: column;
          // padding-left: 15px;
          // padding-top: 10px;
          // width: 310px;
          .xuhao {
            color: #dadada;
            font-size: 2.5rem;
            // width: 50px;
            height: 2rem;
            padding-left: 2rem;
            margin-top:1rem;
          }
          .name {
            font-size: 1.5rem;
            // font-weight: bold;
            margin: 1.5rem 0.5rem 1.8rem 0;
            font-family: 'Adobe Heiti Std';
            font-weight: bold;
            // width: 270px;
            height: 2rem;
            padding-left: 2rem;
          }
          .line {
            width: 4.5rem;
            border: 1px solid #1a87d9;
            margin-bottom: 3.5rem;
            margin-left: 2rem;
          }
          .text {
            font-size: 1.2rem;
            padding-left: 2rem;
            //width: 266px;
            line-height: 2.8rem;
          }
        }
        .content:hover {
          background: #103f83;
          color: #fff;
          cursor: pointer;
          .line {
            border: 1px solid #fff;
          }
        }
      }
    }
  }
}
</style>