<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>
<script>
	;(function(win, doc) {
	  if (!win.addEventListener) return
	  var html = document.documentElement
	  function setFont() {
	    var html = document.documentElement
	    var k = 1380
	    html.style.fontSize = (html.clientWidth / k) * 10 + 'px'
	  }
	  setFont()
	  setTimeout(function() {
	    setFont()
	  }, 300)
	  doc.addEventListener('DOMContentLoaded', setFont, false)
	  win.addEventListener('resize', setFont, false)
	  win.addEventListener('load', setFont, false)
	})(window, document)
</script>
<style lang="less">
*{
  margin:0;
  padding:0;
}
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

