import { render, staticRenderFns } from "./corporateNews.vue?vue&type=template&id=207987e8&scoped=true&"
import script from "./corporateNews.vue?vue&type=script&lang=js&"
export * from "./corporateNews.vue?vue&type=script&lang=js&"
import style0 from "./corporateNews.vue?vue&type=style&index=0&id=207987e8&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207987e8",
  null
  
)

export default component.exports