var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticClass:"product-cont"},[_c('div',{staticClass:"product-cont-title"},[_c('div',{staticClass:"product-cont-title-info",on:{"click":_vm.jump}},[_vm._m(0),_c('div',{staticClass:"product-cont-title-info-chinese"},[_vm._v("软件产品")])]),_c('div',{staticClass:"product-cont-title-know",on:{"click":_vm.jump}},[_vm._v("了解更多")])]),_c('div',{staticClass:"product-cont-info"},[_c('div',{staticClass:"product-cont-info-detail"},[_c('div',{staticClass:"product-cont-info-detail-content"},[_c('div',{staticClass:"module",on:{"click":_vm.jump}},[_vm._m(1),_c('div',{staticClass:"line"}),_vm._m(2)]),_c('div',{staticClass:"module",on:{"click":_vm.jump}},[_vm._m(3),_c('div',{staticClass:"line"}),_vm._m(4)]),_c('div',{staticClass:"module",on:{"click":_vm.jump}},[_vm._m(5),_c('div',{staticClass:"line"}),_vm._m(6)]),_c('div',{staticClass:"module",on:{"click":_vm.jump}},[_vm._m(7),_c('div',{staticClass:"line"}),_vm._m(8)]),_c('div',{staticClass:"module",on:{"click":_vm.jump}},[_vm._m(9),_c('div',{staticClass:"line"}),_vm._m(10)]),_c('div',{staticClass:"module",on:{"click":_vm.jump}},[_vm._m(11),_c('div',{staticClass:"line"}),_vm._m(12)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-cont-title-info-enlish"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"text"},[_vm._v("Software Product")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img1"},[_c('img',{attrs:{"src":require("../../assets/imgs/zhengwu-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("智慧政务")]),_c('div',[_vm._v("政党机关信息技术创新")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img2"},[_c('img',{attrs:{"src":require("../../assets/imgs/guofang-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("智慧国防")]),_c('div',[_vm._v("军工企业信息化建设")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img3"},[_c('img',{attrs:{"src":require("../../assets/imgs/yiliao-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("智慧医疗")]),_c('div',[_vm._v("数字医院 移动医疗 电子档案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img4"},[_c('img',{attrs:{"src":require("../../assets/imgs/gongan-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("智慧交通")]),_c('div',[_vm._v("智能交通 定位管理 精准导航")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img5"},[_c('img',{attrs:{"src":require("../../assets/imgs/jiaoyu-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("智慧教育")]),_c('div',[_vm._v("智慧校园 智慧课堂 智慧教研")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img6"},[_c('img',{attrs:{"src":require("../../assets/imgs/yuanqu-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',[_vm._v("智慧园区")]),_c('div',[_vm._v("数字展厅 孪生工厂 智能监控")])])
}]

export { render, staticRenderFns }