<template>
  <div class="agency">
    <div class="agency-main">
      <div class="agency-main-title">
        <div class="agency-main-title-enlish">
          <div class="line"></div>
          <div class="text">Cooperative Agency</div>
        </div>
        <div class="agency-main-title-chinese">合作机构</div>
      </div>
      <div class="agency-main-tenet">
        <div class="agency-main-tenet-text">
          <span>携手企业</span><span>合作共赢</span>
        </div>
      </div>
      <div class="agency-main-org">
        <!-- <el-carousel
          indicator-position="none"
          :interval="5000"
          arrow="never"
          height="350px"
        >
          <el-carousel-item> -->
            <el-row :gutter="20" class="agency-main-org-cont">
              <el-col :span="4" v-for="(item, i) in list" :key="i">
                <div class="img"><img :src="item.img" alt="" /></div>
              </el-col>
            </el-row>
            <!-- <div class="item-div">
              <div class="agency-main-org-cont">
                <div class="img" v-for="(item, i) in list" :key="i">
                  <img :src="item.img" alt="" />
                </div>
              </div>
            </div> -->
          <!-- </el-carousel-item>
          <el-carousel-item>
            <el-row :gutter="20" class="agency-main-org-cont">
              <el-col :span="6" v-for="(item, i) in list1" :key="i">
                <div class="img"><img :src="item.img" alt="" /></div>
              </el-col>
            </el-row>
          </el-carousel-item>
          <el-carousel-item>
            <el-row :gutter="20" class="agency-main-org-cont">
              <el-col :span="6" v-for="(item, i) in list2" :key="i">
                <div class="img"><img :src="item.img" alt="" /></div>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel> -->
        <!-- <div></div> -->
        <!-- <div class="agency-main-org-cont">
          <div class="img" v-for="(item, i) in list" :key="i">
            <img :src="item.img" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          img: require("../../assets/img/1.中国电信logo.png"),
        },
        {
          img: require("../../assets/img/2.中国移动Logo.png"),
        },
        {
          img: require("../../assets/img/3.中国联通logo.png"),
        },
        {
          img: require("../../assets/img/4.中国建筑Logo.png"),
        },
        {
          img: require("../../assets/img/5.腾讯logo.png"),
        },
        {
          img: require("../../assets/img/6.阿里云logo.png"),
        },
        {
          img: require("../../assets/img/7.金山logo.png"),
        },
        {
          img: require("../../assets/img/8.huawei_logo.png"),
        },
        {
          img: require("../../assets/img/9.H3Clogo.png"),
        },
        {
          img: require("../../assets/img/10.浪潮a.png"),
        },
        {
          img: require("../../assets/img/11.联想Logo.png"),
        },
        {
          img: require("../../assets/img/12.惠普Logo.png"),
        },

        {
          img: require("../../assets/img/13.锐捷logo.png"),
        },
        {
          img: require("../../assets/img/14.海康威视-Logo.png"),
        },
        {
          img: require("../../assets/img/15.大华Logo.png"),
        },
        {
          img: require("../../assets/img/16.享视Logo.png"),
        },
      
        {
          img: require("../../assets/img/17.洲明Logo.png"),
        },
        {
          img: require("../../assets/img/18.利亚德Logo.jpg"),
        },
        {
          img: require("../../assets/img/19.Absen-logo.png"),
        },
        {
          img: require("../../assets/img/20.优联.png"),
        },
        {
          img: require("../../assets/img/21.立方Logo.png"),
        },
        {
          img: require("../../assets/img/22.科拓Logo.png"),
        },
        {
          img: require("../../assets/img/23.中控logo.png"),
        },
        {
          img: require("../../assets/img/24.ITC logo.png"),
        },
        {
          img: require("../../assets/img/25.湖山logo.png"),
        },
        {
          img: require("../../assets/img/26.博士Logo.jpg"),
        },
        {
          img: require("../../assets/img/27.大金logo.png"),
        },
        {
          img: require("../../assets/img/28.江森logo.jpg"),
        },
        {
          img: require("../../assets/img/29.西门子logo.png"),
        },
        {
          img: require("../../assets/img/30.艾默生logo.png"),
        },
        {
          img: require("../../assets/img/31.科士达Logo.png"),
        },
        {
          img: require("../../assets/img/32.诺电Logo.jpg"),
        },
      
        {
          img: require("../../assets/img/33.天融信Logo.jpg"),
        },
        {
          img: require("../../assets/img/34.深信服Logo.png"),
        },
        {
          img: require("../../assets/img/35.奇安信logo.png"),
        },
        {
          img: require("../../assets/img/36.曙光Logo.png"),
        },
        {
          img: require("../../assets/img/37.达梦logo.png"),
        },
        {
          img: require("../../assets/img/38.北信源Logo.png"),
        },
        {
          img: require("../../assets/img/39.中孚logo.png"),
        },
        {
          img: require("../../assets/img/40.西蒙Logo.png"),
        },
        {
          img: require("../../assets/img/41.康普logo.png"),
        },
        {
          img: require("../../assets/img/42.帝一logo.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.agency {
  top: 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  height: 86rem;
  width: 100%;
  background: url("../../assets/imgs/banner-4.png") no-repeat;
  background-size: 100% 100%;
  &-main {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    width: 90%;
    position: relative;
    &-title {
      height: 15%;
      width: 100%;
      &-enlish {
        display: flex;
        .line {
          width: 6rem;
          border: 1px solid #fff;
          height: 0;
          margin: 2rem 0.5rem 0 0;
        }
        .text {
          color: #fff;
          font-size: 2rem;
          //font-weight: bold;
          width: 29rem;
          font-family: "Impact";
        }
      }
      &-chinese {
        font-size: 3rem;
        font-weight: bold;
        color: #fff;
        width: 19rem;
        height: 4rem;
        margin-top: 1.8rem;
        letter-spacing: 1rem;
      }
    }
    &-tenet {
      height: 15%;
      width: 100%;
      margin-bottom: 2rem;
      margin-left: 8.5rem;
      &-text {
        color: #fff;
        font-size: 4rem;
        margin: 1.8rem 0 1rem 10rem;
        font-family: "Adobe Heiti Std";
        height: 52rem;
        letter-spacing: 1rem;
        & > span:nth-of-type(2) {
          margin-left: 1rem;
        }
      }
    }
    &-org {
      flex: 1;
      width: 89%;
      top: 25rem;
      right: 0;
      position: absolute;
      height: 100%;
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 5rem;
      margin-top: 5rem;
      // background: #f00;
      // & > div:nth-of-type(1) {
      //   width: 30%;
      //   height: 30%;
      // }
      &-cont {
        width: 100%;
        // height: 380px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .img {
          height: 6rem;
          //height: 18%;
          width: 100%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          & > img {
            width: 65%;
            //height: 50%;
          }
        }
      }
    }
  }
}
// .el-carousel__container {
//   .el-carousel__arrow--left {
//     left: -100px;
//   }
//   .el-carousel__arrow--right {
//     right: -100px;
//   }
// }
.el-carousel__item{
  left: 1px;
}
</style>
