import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routers = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
  {
    path: '/honor',
    name: 'honor',
    component: () => import(/* webpackChunkName: "honor" */ '../views/honor.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/newsDetail.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/case/government',
    name: 'government',
    component: () => import('../views/government.vue')
  },
  {
    path: '/case/security',
    name: 'security',
    component: () => import('../views/security.vue')
  },
  {
    path: '/case/detail',
    component: () => import('../views/columnDetail.vue'),
    children:[
      {
        path:'government',
        component:()=>import('../views/CaseDetail/government.vue')
      },
      {
        path:'security',
        component:()=>import('../views/CaseDetail/security.vue')
      },
      {
        path:'hospital',
        component:()=>import('../views/CaseDetail/hospital.vue')
      },
      {
        path:'safety',
        component:()=>import('../views/CaseDetail/safety.vue')
      },
      {
        path:'education',
        component:()=>import('../views/CaseDetail/education.vue')
      },
      {
        path:'garden',
        component:()=>import('../views/CaseDetail/garden.vue')
      },
    ]
  },
  {
    path: '/case/defense',
    name: 'defense',
    component: () => import('../views/defense.vue')
  },
  {
    path: '/case/education',
    name: 'education',
    component: () => import('../views/education.vue')
  },
  {
    path: '/case/medical',
    name: 'medical',
    component: () => import('../views/medical.vue')
  },
  {
    path: '/case/garden',
    name: 'garden',
    component: () => import('../views/garden.vue')
  },
]

const router = new VueRouter({
  routes: routers,
  //判断路由跳转之后是否是发生滚动 如果发生了滚动返回到最上面
  scrollBehavior(to, from, saveTop){
    if (saveTop) {
        return saveTop;
    } else {
        return {x: 0, y: 0}
    }
}
})

export default router
