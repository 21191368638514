<template>
  <div class="product">
    <div class="product-cont">
      <div class="product-cont-title">
        <div class="product-cont-title-info" @click="jump">
          <div class="product-cont-title-info-enlish">
            <div class="line"></div>
            <div class="text">Software Product</div>
          </div>
          <div class="product-cont-title-info-chinese">软件产品</div>
        </div>
        <div class="product-cont-title-know" @click="jump">了解更多</div>
      </div>
      <div class="product-cont-info">
        <!-- <div class="product-cont-info-know">
          <div class="product-cont-info-know-text">了解更多</div>
        </div> -->
        <div class="product-cont-info-detail">
          <div class="product-cont-info-detail-content">
            <div class="module" @click="jump">
              <div class="img1">
                <img src="../../assets/imgs/zhengwu-2.png" alt="" />
              </div>
              <div class="line"></div>
              <div class="text">
                <div>智慧政务</div>
                <div>政党机关信息技术创新</div>
              </div>
            </div>
            <div class="module" @click="jump">
              <div class="img2">
                <img src="../../assets/imgs/guofang-2.png" alt="" />
              </div>
              <div class="line"></div>
              <div class="text">
                <div>智慧国防</div>
                <div>军工企业信息化建设</div>
              </div>
            </div>
            <div class="module" @click="jump">
              <div class="img3">
                <img src="../../assets/imgs/yiliao-2.png" alt="" />
              </div>
              <div class="line"></div>
              <div class="text">
                <div>智慧医疗</div>
                <div>数字医院 移动医疗 电子档案</div>
              </div>
            </div>
            <div class="module" @click="jump">
              <div class="img4">
                <img src="../../assets/imgs/gongan-2.png" alt="" />
              </div>
              <div class="line"></div>
              <div class="text">
                <div>智慧交通</div>
                <div>智能交通 定位管理 精准导航</div>
              </div>
            </div>
            <div class="module" @click="jump">
              <div class="img5">
                <img src="../../assets/imgs/jiaoyu-2.png" alt="" />
              </div>
              <div class="line"></div>
              <div class="text">
                <div>智慧教育</div>
                <div>智慧校园 智慧课堂 智慧教研</div>
              </div>
            </div>
            <div class="module" @click="jump">
              <div class="img6">
                <img src="../../assets/imgs/yuanqu-2.png" alt="" />
              </div>
              <div class="line"></div>
              <div class="text">
                <div>智慧园区</div>
                <div>数字展厅 孪生工厂 智能监控</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // gotoMenu1() {
    //   //指定跳转的地址
    //   this.$router.replace("/case/government");
    // },
    // gotoMenu2() {
    //   //指定跳转的地址
    //   this.$router.replace("/case/defense");
    // },
    // gotoMenu3() {
    //   //指定跳转的地址
    //   this.$router.replace("/case/medical");
    // },
    // gotoMenu4() {
    //   //指定跳转的地址
    //   this.$router.replace("/case/security");
    // },
    // gotoMenu5() {
    //   //指定跳转的地址
    //   this.$router.replace("/case/education");
    // },
    // gotoMenu6() {
    //   //指定跳转的地址
    //   this.$router.replace("/case/garden");
    // },

    jump() {
      this.$router.replace("/product");
    },
  },
};
</script>

<style scoped lang="less">
.product {
  top: 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  // height: 80vh;
  height: 68rem;
  width: 100%;
  background-color: #f1f5f8;
  margin-top: 2rem;
  &-cont {
    margin-top: 3rem;
    display: flex;
    width: 90%;
    flex-direction: column;
    &-title {
      height: 15%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      //cursor: pointer;
      &-info {
        cursor: pointer;
        &-enlish {
          display: flex;
          // width: 250px;
          .line {
            width: 6rem;
            border: 1px solid #1a2d51;
            height: 0;
            margin: 1.8rem 0.5rem 0 0;
          }
          .text {
            font-family: 'Impact';
            color: #1a2d51;
            font-size: 3rem;
            //font-weight: bold;
            height: 3rem;
            width: 35rem;
          }
        }
        &-chinese {
          font-size: 3rem;
          font-weight: bold;
          color: #1a2d51;
          height: 3.5rem;
          width: 25rem;
          margin-top: 2.5rem;
          letter-spacing: 0.8rem;
        }
      }
      &-know {
        width: 16rem;
        height: 5rem;
        border: 1px solid #3e5f96;
        border-radius: 2.5rem;
        color: #3e5f96;
        line-height: 5rem;
        margin-top: 2rem;
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
    &-info {
      display: flex;
      flex: 1;
      &-detail {
        flex: 1;
        margin: 5rem 0 0 20rem;
        // margin-top:50px;
        &-content {
          width: 100%;
          height: 80%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          & > div {
            width: 30%;
            height: 45%;
            margin-bottom: 2rem;
          }
          .module {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            cursor: pointer;
            margin-bottom: 4rem;
            // width: 465px;
            // height: 180px;
            .img1 {
              flex: 0.5;
              & > img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
              }
            }
            .img2 {
              flex: 0.5;
              & > img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
              }
            }
            .img3 {
              flex: 0.5;
              & > img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
              }
            }
            .img4 {
              flex: 0.5;
              & > img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
              }
            }
            .img5 {
              flex: 0.5;
              & > img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
              }
            }
            .img6 {
              flex: 0.5;
              & > img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
              }
            }
            .line {
              height: 5rem;
              line-height: 5rem;
              border: 1px solid #ebebeb;
              margin-right: 1rem;
            }
            .text {
              flex: 1;
              margin-left: 0px;
              & > div:nth-of-type(1) {
                font-size: 2rem;
                font-weight: bold;
                height: 3rem;
                width: 15rem;
              }
              & > div:nth-of-type(2) {
                color: #797979;
                font-size: 1rem;
                height: 2rem;
                width: 20rem;
                margin-top: 0.5rem;
              }
            }
          }
          :hover {
            color: #fff;
            background: #103f83;
            & .text > div:nth-of-type(2) {
              color: #fff;
            }
            & .img1 > img {
              content: url("../../assets/imgs/zhengwu-1.png");
            }
            & .img2 > img {
              content: url("../../assets/imgs/guofang-1.png");
            }
            & .img3 > img {
              content: url("../../assets/imgs/yiliao-1.png");
            }
            & .img4 > img {
              content: url("../../assets/imgs/gongan-1.png");
            }
            & .img5 > img {
              content: url("../../assets/imgs/jiaoyu-1.png");
            }
            & .img6 > img {
              content: url("../../assets/imgs/yuanqu-1.png");
            }
          }
        }
      }
    }
  }
}
</style>
