import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title:'智慧政务'
  },
  mutations: {
    changeCommit(state, value) {
      state.title = value;
    },
    changeTitleCommit(state, value) {
      state.name = value
    }
  },
  actions: {
    changeDispatch(context, value) {
      context.commit("changeCommit", value);
    },
    changeDispatchTitle(context, value) {
      context.commit("changeTitleCommit", value);
    }
  },
  modules: {
  }
})
