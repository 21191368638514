import Vue from 'vue'
import router from './router'
import store from './store'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/font/text.css";
import "@/assets/font/Impact.ttf";
import VueResizeText from 'vue-resize-text';

import App from './App.vue'

Vue.use(ElementUI).use(VueResizeText);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
